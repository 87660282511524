import { Component, ElementRef, HostListener } from '@angular/core';

import { Platform, AlertController } from '@ionic/angular';
import { Angulartics2GoogleTagManager } from 'angulartics2';

import { environment } from '../environments/environment';

import { CoreService, LogService, ScreensizeService } from '@roperetail/ngx-ionic-ecommerce';
import { StorageService } from '@roperetail/ngx-ionic-ecommerce';
import { Storage } from '@ionic/storage-angular';
import { NavigatorService } from '@services/core/navigator.service';

import { SetupService } from '@services/core/setup.service';
import { Performance } from '@angular/fire/performance';
import { SplashScreen } from '@capacitor/splash-screen';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html'
})
export class AppComponent {

  public storageReady = false;
  public isDesktop = false;

  public author: string = environment.author;
  public version: string = environment.version;

  constructor(
    public performance: Performance,
    public storage: Storage,
    public platform: Platform,
    public coreService: CoreService,
    public setupService: SetupService,
    public storageService: StorageService,
    private screensizeService: ScreensizeService,
    public angulartics2GoogleTagManager: Angulartics2GoogleTagManager,
    public navigator: NavigatorService,
    public alertCtrl: AlertController,
    private logService: LogService,
    public elementRef: ElementRef
  ) {

    this.logService.info('Loading app...');

    this.screensizeService.onResize(this.platform.width());
    this.screensizeService.isDesktopView().subscribe(isDesktop => {
      this.isDesktop = isDesktop;
    });

    this.storageService.ready(this.storage).then(() => {
      this.storageReady = true;
    });

  }

  @HostListener('window:resize', ['$event'])
  public onResize(event) {
    this.screensizeService.onResize(event.target.innerWidth);
  }

  public async initializeApp() {

    this.coreService.viewReady = true;
    this.logService.info('The view is ready.', true);

    await SplashScreen.hide();

    this.angulartics2GoogleTagManager.startTracking();

    this.coreService.setUsps([
      {
        icon: 'car-outline',
        message: '<b>Supersnelle verzending</b>'
      },
      {
        icon: 'shirt-outline',
        message: 'Elke week een <b>nieuwe betaalbare collectie</b>'
      },
      {
        icon: 'cash-outline',
        message: 'iDEAL, Mastercard of <b>Achteraf betalen met Billink</b>'
      },
      {
        icon: 'chatbubble-ellipses-outline',
        message: 'Wij scoren een <b>9,4 (1700+ reviews)</b>'
      },
      {
        icon: 'storefront-outline',
        message: 'Winkels in <b>Schijndel, Rosmalen en Best</b>'
      },
      {
        icon: 'car-outline',
        message: 'Bestel je voor 23:59? <b>Vaak morgen al in huis!</b>'
      }
    ]);

  }

}
