<ion-header>
  <ion-toolbar color="tertiary">
    <ion-title>Pasvorm assistent</ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="dismiss()">
        Sluit
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content #content>

  <div class="blocks blocks-small">

    <ng-container *ngIf="!sizesPresent">

      <div class="block">
      
        <ion-card-header>
          <ion-card-title>Maat</ion-card-title>
          <ion-card-subtitle>Wij weten je maat nog niet, of nog niet goed genoeg om je een goed advies te kunnen geven. Je kunt hieronder de maat opgeven die je normaal hebt, dan geven wij jou een duidelijk maatadvies voor dit product.</ion-card-subtitle>
        </ion-card-header>
      
        <ion-card-content class="ion-text-center">
          <ion-range min="100" max="600" steps="25" ticks="true" (ionChange)="changeSize($event)" [(ngModel)]="currentSize" color="secondary">
            <ion-label slot="start" style="font-size: 1.2rem; font-weight: 500;">XS</ion-label>
            <ion-label slot="end" style="font-size: 1.2rem; font-weight: 500;">XXL</ion-label>
          </ion-range>
          <br>
          <ion-chip>
            <ion-label style="font-weight: 700;">{{ sizeLabel ? sizeLabel : 'Schuif hierboven naar je maat...' }}</ion-label>
            <ion-icon name="checkmark-circle" color="success"></ion-icon>
          </ion-chip>
        </ion-card-content>

        <div style="width: 100%; display: flex; align-items: center; justify-content: center;">
          <ion-button color="primary" [disabled]="currentSize === undefined" (click)="goToAdvice()">
            Bekijk je maatadvies
          </ion-button>
        </div>
      
      </div>

    </ng-container>

    <ng-container *ngIf="sizesPresent">

      <div class="block" *ngIf="bestSize">
      
        <ion-card-header>
          <ion-card-title>Ons advies</ion-card-title>
          <ion-card-subtitle>
            Welke maat kun je het beste bestellen? We houden hierbij rekening met jouw maat, de gewenste fit en hoe het
            artikel valt. Het percentage geeft de kans aan dat dit product in deze maat goed voor je is.
          </ion-card-subtitle>
        </ion-card-header>
      
        <ion-card-content class="ion-text-center">
          <div class="bestsize-chip bestsize">
            <div class="bestsize-label">Maat {{ bestSize.size.name }}</div>
            <div class="bestsize-percent">{{ bestSize.fitChance | percent }}</div>
          </div>
          <div class="bestsize-chip semisize">
            <div class="semisize-label">Maat {{ secondBestSize.size.name }}</div>
            <div class="semisize-percent">{{ secondBestSize.fitChance | percent }}</div>
          </div>
        </ion-card-content>
      
      </div>

      <ion-item-divider color="light" (click)="showRanges = true">
        <ion-label style="font-family: 'Source Sans Pro';" class="ion-text-wrap">
          Wil je graag weten hoe we op dit advies komen? Of wil je jouw ingestelde maat of fit aanpassen?
        </ion-label>
        <ion-buttons slot="end" style="margin-right: 5px; z-index: 1;">
          <ion-button fill="solid" color="light">
            <ion-label>Klik hier</ion-label>
            <ion-icon slot="end" size="small" name="arrow-down"></ion-icon>
          </ion-button>
        </ion-buttons>
      </ion-item-divider>

      <ng-container *ngIf="showRanges">

        <div class="block">
      
          <ion-card-header>
            <ion-card-title>Maat</ion-card-title>
            <ion-card-subtitle>Jouw maat volgens ons systeem</ion-card-subtitle>
          </ion-card-header>

          <ion-card-content class="ion-text-center">
            <ion-range min="100" max="600" steps="25" ticks="true" (ionChange)="changeSize($event)" [(ngModel)]="currentSize" color="secondary">
              <ion-label slot="start" style="font-size: 1.2rem; font-weight: 500;">XS</ion-label>
              <ion-label slot="end" style="font-size: 1.2rem; font-weight: 500;">XXL</ion-label>
            </ion-range>
            <br>
            <ion-chip>
              <ion-label style="font-weight: 700;">{{ sizeLabel }}</ion-label>
              <ion-icon name="checkmark-circle" color="success"></ion-icon>
            </ion-chip>
          </ion-card-content>

        </div>

        <div class="block">
        
          <ion-card-header>
            <ion-card-title>Pasvorm</ion-card-title>
            <ion-card-subtitle>Hoe valt het artikel volgens ons?</ion-card-subtitle>
          </ion-card-header>
        
          <ion-card-content class="ion-text-center">
            <ion-range min="-100" max="100" steps="50" ticks="true" disabled="true" [(ngModel)]="currentProductFit" color="secondary">
              <ion-label slot="start" style="font-size: 1.2rem; font-weight: 500;">Erg klein</ion-label>
              <ion-label slot="end" style="font-size: 1.2rem; font-weight: 500;">Erg groot</ion-label>
            </ion-range>
            <br>
            <ion-chip>
              <ion-label style="font-weight: 700;">{{ productFitLabel }}</ion-label>
              <ion-icon name="checkmark-circle" color="success"></ion-icon>
            </ion-chip>
          </ion-card-content>
        
        </div>

        <div class="block">
        
          <ion-card-header>
            <ion-card-title>Jouw Fit</ion-card-title>
            <ion-card-subtitle>Hoe wil je dat het valt?</ion-card-subtitle>
          </ion-card-header>
        
          <ion-card-content class="ion-text-center">
            <ion-range min="-100" max="100" steps="100" ticks="true" (ionChange)="changeFit($event)" [(ngModel)]="currentFit" color="secondary">
              <ion-label slot="start" style="font-size: 1.2rem; font-weight: 500;">Aangesloten</ion-label>
              <ion-label slot="end" style="font-size: 1.2rem; font-weight: 500;">Oversized</ion-label>
            </ion-range>
            <br>
            <ion-chip>
              <ion-label style="font-weight: 700;">{{ fitLabel }}</ion-label>
              <ion-icon name="checkmark-circle" color="success"></ion-icon>
            </ion-chip>
          </ion-card-content>
        
        </div>

      </ng-container>

    </ng-container>

  </div>

</ion-content>

<rope-footer
  [content]="content"
  [isModal]="true"
></rope-footer>
