<ion-header>
    <ion-toolbar color="tertiary">
        <ion-title>Mijn adresgegevens</ion-title>
        <ion-buttons slot="end">
            <ion-button (click)="dismiss({ status: 0 })" color="secondary">
                <ion-icon slot="icon-only" name="close-circle-outline"></ion-icon>
            </ion-button>
        </ion-buttons>
    </ion-toolbar>
</ion-header>

<ion-content class="ion-padding" #content>

    <rope-user-address-details 
      (onDismiss)="dismiss($event)" 
      (modalAutocomplete)="modalAutocomplete($event)"
    ></rope-user-address-details>

</ion-content>