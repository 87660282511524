<ion-header>
  <ion-toolbar color="primary">
    <ion-title>Afbeeldingen</ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="dismiss()">
        Sluit
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content #content>

  <div class="blocks">

    <div *ngFor="let image of images; let i = index;" class="block">
      <ion-card-header>
        <ion-card-title>Afbeelding #{{ i+1 }}</ion-card-title>
      </ion-card-header>

      <ion-card-content>
        <img [src]="image.url">
      </ion-card-content>
    </div>

  </div>


</ion-content>

<rope-footer [content]="content"></rope-footer>