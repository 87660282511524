import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';

@Component({
  selector: 'app-images',
  templateUrl: './images.page.html',
  styleUrls: ['./images.page.scss'],
})
export class ImagesPage implements OnInit {

  public images;

  constructor(
    public modalCtrl: ModalController,
    public navParams: NavParams
  ) { }

  ngOnInit() {
    this.images = this.navParams.get('images');
  }

  public dismiss() {
    this.modalCtrl.dismiss();
  }

}
