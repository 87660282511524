import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { NavController } from '@ionic/angular';
import { AuthService } from '@roperetail/ngx-ionic-ecommerce';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService {

  constructor(
    public auth: AuthService, 
    public navCtrl: NavController
  ) {}

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

    await this.auth.ready();
    if (!this.auth.isAuthenticated()) {
      this.navCtrl.navigateRoot('login');
      return false;
    }
    return true;

  }
  
}
