import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { IonContent, ModalController } from '@ionic/angular';
import { AuthService } from '@roperetail/ngx-ionic-ecommerce';
import { NavigatorService } from '@services/core/navigator.service';
import { RegisterPage } from '../register/register.page';

@Component({
  selector: 'app-login',
  templateUrl: './login.page.html',
  styleUrls: ['./login.page.scss'],
})
export class LoginPage implements OnInit {

  @ViewChild(IonContent) content: IonContent;
  @Input() public origin: any;

  public topText = 'Meld je aan en krijg toegang tot de KiBa Family! Dit is de plek waar je bestellingen kunt plaatsen, winkelreserveringen kunt doen of je kunt aanmelden voor een fashion box!'
  public text = 'Log hier in met je e-mailadres en wachtwoord. Heb je al een account in een van onze winkels aan laten maken? Registreer je dan met dat e-mailadres, deze wordt dan automatisch gekoppeld.';
  public imageUrl = 'https://storage.googleapis.com/kiba-fashion-api-bucket/misc/registerheader_image.jpg';

  constructor(
    public modalCtrl: ModalController,
    public navigator: NavigatorService,
    public authService: AuthService
  ) {
  }

  ngOnInit() {
    
  }

  public checkOrigin() {
    return this.origin;
  }

  public dismiss(data?) {
    this.modalCtrl.dismiss(data);
  }

  public moveOnAsGuest() {
    
    this.authService.isGuestMode = true;
    this.register(true);

  }

  public async register(guestMode?) {

    if (this.checkOrigin()) {

      const registerModal = await this.modalCtrl.create({
        component: RegisterPage,
        componentProps: { origin: this.origin, guestMode: guestMode },
        cssClass: 'big-modal'
      });
      await registerModal.present();

      registerModal.onDidDismiss().then((data) => {

        if (data.data && data.data.status === 1) {
          this.dismiss(data);
        } else {
          this.dismiss();
        }

      });

    } else {
      this.navigator.navigate('/register');
    }

  }

}
