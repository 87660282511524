import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { CartShipmentPageRoutingModule } from './cart-shipment-routing.module';

import { CartShipmentPage } from './cart-shipment.page';
import { RopeNgxIonicEcommerceModule } from '@roperetail/ngx-ionic-ecommerce';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    CartShipmentPageRoutingModule,
    RopeNgxIonicEcommerceModule
  ],
  declarations: [CartShipmentPage]
})
export class CartShipmentPageModule {}
