import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NavController, PopoverController, ModalController } from '@ionic/angular';
import { CoreService, Outfit } from '@roperetail/ngx-ionic-ecommerce';

@Injectable({
  providedIn: 'root'
})
export class NavigatorService {

  constructor(
    public navCtrl: NavController,
    public coreService: CoreService,
    public router: Router,
    public popoverCtrl: PopoverController,
    public modalCtrl: ModalController,
  ) { }

  public back() {
    this.navCtrl.back();
  }

  public home() {
    this.navCtrl.navigateRoot('/home');
  }

  public navigateNewWindow(url) {
    this.coreService.openUrl(url, '_blank');
  }

  public navigate(url) {
    this.navCtrl.navigateRoot(url);
  }

  public navigateBack(url) {
    this.navCtrl.navigateBack(url);
  }

  public navigateForward(url) {
    this.navCtrl.navigateForward(url);
  }

  public checkLogOut() {

    const authGuarded = ['/']

  }

  public shopDetail(outfit: Outfit) {
    this.navCtrl.navigateForward('/shop/' + outfit.outfit_type.toLowerCase() + '/' + outfit.id);
  }

  public cart() {
    this.navCtrl.navigateRoot('/shopping-bag');
  }

  public wishlist() {
    this.navCtrl.navigateRoot('/wishlist');
  }

  public search() {
    this.navCtrl.navigateRoot('/webshop-search');
  }

  public openOrder() {
    this.navCtrl.navigateForward('/cart-do-payment');
  }

  public async openOnlineShopping(ev?) {

    const event = ev.event;
    const isDesktop = ev.isDesktop;

    // if (isDesktop) {

    //   event.preventDefault();
    //   let popover = await this.popoverCtrl.create({
    //     component: OnlineShoppingPage,
    //     componentProps: {
    //       isDesktop: isDesktop
    //     },
    //     event: event,
    //     showBackdrop: false,
    //     cssClass: 'online-shopping-popover'
    //   });

    //   return await popover.present();

    // } else if (!event) {

    //   this.coreService.onlineShoppingOpen.next(true);
    //   let modal = await this.modalCtrl.create({
    //     component: OnlineShoppingPage,
    //     componentProps: {
    //       isDesktop: isDesktop
    //     },
    //     showBackdrop: true,
    //     cssClass: 'online-shopping-modal'
    //   });

    //   modal.onDidDismiss().then(() => {
    //     this.coreService.onlineShoppingOpen.next(false);
    //   });
    //   return await modal.present();

    // }

  }
}
