import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: "fashionbox",
    redirectTo: "subscription",
    pathMatch: "full",
  },
  {
    path: 'address-detail',
    loadChildren: () => import('./pages/account/address-detail/address-detail.module').then( m => m.AddressDetailPageModule)
  },
  {
    path: 'customer-membership-management',
    loadChildren: () => import('./pages/account/customer-membership-management/customer-membership-management.module').then( m => m.CustomerMembershipManagementPageModule)
  },
  {
    path: 'watchlist',
    loadChildren: () => import('./pages/account/future-reservation-overview/future-reservation-overview.module').then( m => m.FutureReservationOverviewPageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/account/login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'loyalty-gift-detail/:id',
    loadChildren: () => import('./pages/account/loyalty/loyalty-gift-detail/loyalty-gift-detail.module').then( m => m.LoyaltyGiftDetailPageModule)
  },
  {
    path: 'loyalty-gift-shop',
    loadChildren: () => import('./pages/account/loyalty/loyalty-gift-shop/loyalty-gift-shop.module').then( m => m.LoyaltyGiftShopPageModule)
  },
  {
    path: 'loyalty-voucher-overview',
    loadChildren: () => import('./pages/account/loyalty/loyalty-voucher-overview/loyalty-voucher-overview.module').then( m => m.LoyaltyVoucherOverviewPageModule)
  },
  {
    path: 'my-account',
    loadChildren: () => import('./pages/account/my-account/my-account.module').then( m => m.MyAccountPageModule)
  },
  {
    path: 'orders/:id',
    loadChildren: () => import('./pages/account/order-detail/order-detail.module').then( m => m.OrderDetailPageModule)
  },
  {
    path: 'orders',
    loadChildren: () => import('./pages/account/order-overview/order-overview.module').then( m => m.OrderOverviewPageModule)
  },
  {
    path: 'register',
    loadChildren: () => import('./pages/account/register/register.module').then( m => m.RegisterPageModule)
  },
  {
    path: 'reservations',
    loadChildren: () => import('./pages/account/reservation-overview/reservation-overview.module').then( m => m.ReservationOverviewPageModule)
  },
  {
    path: 'reset-password',
    loadChildren: () => import('./pages/account/reset-password/reset-password.module').then( m => m.ResetPasswordPageModule)
  },
  {
    path: 'user-detail',
    loadChildren: () => import('./pages/account/user-detail/user-detail.module').then( m => m.UserDetailPageModule)
  },
  {
    path: 'cart-check-payment',
    loadChildren: () => import('./pages/core/cart/cart-check-payment/cart-check-payment.module').then( m => m.CartCheckPaymentPageModule)
  },
  {
    path: 'cart-do-payment',
    loadChildren: () => import('./pages/core/cart/cart-do-payment/cart-do-payment.module').then( m => m.CartDoPaymentPageModule)
  },
  {
    path: 'cart-payment-status',
    loadChildren: () => import('./pages/core/cart/cart-payment-status/cart-payment-status.module').then( m => m.CartPaymentStatusPageModule)
  },
  {
    path: 'cart-shipment',
    loadChildren: () => import('./pages/core/cart/cart-shipment/cart-shipment.module').then( m => m.CartShipmentPageModule)
  },
  {
    path: 'cart-summary',
    loadChildren: () => import('./pages/core/cart/cart-summary/cart-summary.module').then( m => m.CartSummaryPageModule)
  },
  {
    path: 'discount-check',
    loadChildren: () => import('./pages/core/cart/discount-check/discount-check.module').then( m => m.DiscountCheckPageModule)
  },
  {
    path: 'home',
    loadChildren: () => import('./pages/core/home/home.module').then( m => m.HomePageModule)
  },
  {
    path: 'shopping-bag',
    loadChildren: () => import('./pages/core/shopping-bag/shopping-bag.module').then( m => m.ShoppingBagPageModule)
  },
  {
    path: 'wishlist',
    loadChildren: () => import('./pages/core/wishlist/wishlist.module').then( m => m.WishlistPageModule)
  },
  {
    path: 'appointment',
    loadChildren: () => import('./pages/inspiration/appointment/appointment-overview/appointment-overview.module').then( m => m.AppointmentOverviewPageModule)
  },
  {
    path: 'create-appointment',
    loadChildren: () => import('./pages/inspiration/appointment/create-appointment/create-appointment.module').then( m => m.CreateAppointmentPageModule)
  },
  {
    path: 'blog-detail/:id',
    loadChildren: () => import('./pages/inspiration/blog/blog-detail/blog-detail.module').then( m => m.BlogDetailPageModule)
  },
  {
    path: 'blogs',
    loadChildren: () => import('./pages/inspiration/blog/blog-overview/blog-overview.module').then( m => m.BlogOverviewPageModule)
  },
  {
    path: 'event-detail/:id',
    loadChildren: () => import('./pages/inspiration/events/event-detail/event-detail.module').then( m => m.EventDetailPageModule)
  },
  {
    path: 'events',
    loadChildren: () => import('./pages/inspiration/events/event-overview/event-overview.module').then( m => m.EventOverviewPageModule)
  },
  {
    path: 'images',
    loadChildren: () => import('./pages/misc/images/images.module').then( m => m.ImagesPageModule)
  },
  {
    path: 'newsletter-signup',
    loadChildren: () => import('./pages/misc/newsletter-signup/newsletter-signup.module').then( m => m.NewsletterSignupPageModule)
  },
  {
    path: 'shop-window/:id',
    loadChildren: () => import('./pages/misc/shop-window/shop-window.module').then( m => m.ShopWindowPageModule)
  },
  {
    path: 'video-player',
    loadChildren: () => import('./pages/misc/video-player/video-player.module').then( m => m.VideoPlayerPageModule)
  },
  {
    path: 'about-this-app',
    loadChildren: () => import('./pages/service/about-this-app/about-this-app.module').then( m => m.AboutThisAppPageModule)
  },
  {
    path: 'about-overview',
    loadChildren: () => import('./pages/service/about/about-overview/about-overview.module').then( m => m.AboutOverviewPageModule)
  },
  {
    path: 'about',
    loadChildren: () => import('./pages/service/about/about-us/about-us.module').then( m => m.AboutUsPageModule)
  },
  {
    path: 'review',
    loadChildren: () => import('./pages/service/about/review/review.module').then( m => m.ReviewPageModule)
  },
  {
    path: 'branches/:id',
    loadChildren: () => import('./pages/service/branches/branch-detail/branch-detail.module').then( m => m.BranchDetailPageModule)
  },
  {
    path: 'branches',
    loadChildren: () => import('./pages/service/branches/branch-overview/branch-overview.module').then( m => m.BranchOverviewPageModule)
  },
  {
    path: 'customer-service-overview',
    loadChildren: () => import('./pages/service/customer-service-overview/customer-service-overview.module').then( m => m.CustomerServiceOverviewPageModule)
  },
  {
    path: 'online-shopping',
    loadChildren: () => import('./pages/service/online-shopping/online-shopping.module').then( m => m.OnlineShoppingPageModule)
  },
  {
    path: 'complaints',
    loadChildren: () => import('./pages/service/terms/complaints/complaints.module').then( m => m.ComplaintsPageModule)
  },
  {
    path: 'cookies',
    loadChildren: () => import('./pages/service/terms/cookies/cookies.module').then( m => m.CookiesPageModule)
  },
  {
    path: 'privacy-policy',
    loadChildren: () => import('./pages/service/terms/privacy-policy/privacy-policy.module').then( m => m.PrivacyPolicyPageModule)
  },
  {
    path: 'returns-policy',
    loadChildren: () => import('./pages/service/terms/returns-policy/returns-policy.module').then( m => m.ReturnsPolicyPageModule)
  },
  {
    path: 'terms',
    loadChildren: () => import('./pages/service/terms/terms/terms.module').then( m => m.TermsPageModule)
  },
  {
    path: 'terms-and-conditions',
    loadChildren: () => import('./pages/service/terms/terms-and-conditions/terms-and-conditions.module').then( m => m.TermsAndConditionsPageModule)
  },
  {
    path: 'terms-overview',
    loadChildren: () => import('./pages/service/terms/terms-overview/terms-overview.module').then( m => m.TermsOverviewPageModule)
  },
  {
    path: 'campaign/:id',
    loadChildren: () => import('./pages/shop/campaign-detail/campaign-detail.module').then( m => m.CampaignDetailPageModule)
  },
  {
    path: 'shop/:category/:id',
    loadChildren: () => import('./pages/shop/product-detail/product-detail.module').then( m => m.ProductDetailPageModule)
  },
  {
    path: 'customer-profile',
    loadChildren: () => import('./pages/shop/subscription/customer-profile/customer-profile.module').then( m => m.CustomerProfilePageModule)
  },
  {
    path: 'personal-stylist',
    loadChildren: () => import('./pages/shop/subscription/personal-stylist/personal-stylist.module').then( m => m.PersonalStylistPageModule)
  },
  {
    path: 'subscription-employee/:id',
    loadChildren: () => import('./pages/shop/subscription/subscription-employee/subscription-employee.module').then( m => m.SubscriptionEmployeePageModule)
  },
  {
    path: 'subscription-info',
    loadChildren: () => import('./pages/shop/subscription/subscription-info/subscription-info.module').then( m => m.SubscriptionInfoPageModule)
  },
  {
    path: 'subscription',
    loadChildren: () => import('./pages/shop/subscription/subscription-overview/subscription-overview.module').then( m => m.SubscriptionOverviewPageModule)
  },
  {
    path: 'subscription/transaction/:id',
    loadChildren: () => import('./pages/shop/subscription/subscription-transaction/subscription-transaction.module').then( m => m.SubscriptionTransactionPageModule)
  },
  {
    path: 'subscription-transaction-archive',
    loadChildren: () => import('./pages/shop/subscription/subscription-transaction-archive/subscription-transaction-archive.module').then( m => m.SubscriptionTransactionArchivePageModule)
  },
  {
    path: 'subscription-transaction-note',
    loadChildren: () => import('./pages/shop/subscription/subscription-transaction-note/subscription-transaction-note.module').then( m => m.SubscriptionTransactionNotePageModule)
  },
  {
    path: 'subscription-transaction-review',
    loadChildren: () => import('./pages/shop/subscription/subscription-transaction-review/subscription-transaction-review.module').then( m => m.SubscriptionTransactionReviewPageModule)
  },
  {
    path: 'shop/:category',
    loadChildren: () => import('./pages/shop/webshop/webshop.module').then( m => m.WebshopPageModule)
  },
  {
    path: 'webshop-filters',
    loadChildren: () => import('./pages/shop/webshop-filters/webshop-filters.module').then( m => m.WebshopFiltersPageModule)
  },
  {
    path: 'webshop-product-mediaset-categories',
    loadChildren: () => import('./pages/shop/webshop-product-mediaset-categories/webshop-product-mediaset-categories.module').then( m => m.WebshopProductMediasetCategoriesPageModule)
  },
  {
    path: 'webshop-search',
    loadChildren: () => import('./pages/shop/webshop-search/webshop-search.module').then( m => m.WebshopSearchPageModule)
  },
  {
    path: 'webshop-theme/:name',
    loadChildren: () => import('./pages/shop/webshop-theme/webshop-theme-detail/webshop-theme-detail.module').then( m => m.WebshopThemeDetailPageModule)
  },
  {
    path: 'webshop-theme-overview',
    loadChildren: () => import('./pages/shop/webshop-theme/webshop-theme-overview/webshop-theme-overview.module').then( m => m.WebshopThemeOverviewPageModule)
  },
  {
    path: 'cart-checkout',
    loadChildren: () => import('./pages/core/cart/cart-checkout/cart-checkout.module').then( m => m.CartCheckoutPageModule)
  },
  {
    path: 'image-viewer',
    loadChildren: () => import('./pages/misc/image-viewer/image-viewer.module').then( m => m.ImageViewerPageModule)
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes)
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
