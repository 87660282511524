export const environment = {
  production: true,
  version: require('../../package.json').version,
  author: require('../../package.json').author,
  firebaseForWebsite: {
    apiKey: "AIzaSyD8lwxprqK6C-uwdKggGnt7xVTKbBLZ6qc",
    authDomain: "kiba-ecommerce.firebaseapp.com",
    projectId: "kiba-ecommerce",
    storageBucket: "kiba-ecommerce.appspot.com",
    messagingSenderId: "685535114093",
    appId: "1:685535114093:web:cdf78c73729791a8ef6064"
  },
  firebaseForChat: {
    apiKey: "AIzaSyBd7Zoamp_EFn_-NF0Onzz6EqHsIeNYjdk",
    authDomain: "rope-retail-chat.firebaseapp.com",
    databaseURL: "https://rope-retail-chat.firebaseio.com",
    projectId: "rope-retail-chat",
    storageBucket: "rope-retail-chat.appspot.com",
    messagingSenderId: "601682344612",
    appId: "1:601682344612:web:872e57401b4a3fdbc3c431"
  }
};
