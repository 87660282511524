<ion-header>
  <ion-toolbar color="tertiary">
    <ion-title>Filters</ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="dismiss()">
        <div class="r-h3">Sluit</div>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content fullscreen #content>
    <rope-webshop-filters
        (dismiss)="dismiss($event)"
    ></rope-webshop-filters>
</ion-content>