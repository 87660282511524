import { Component, Input, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';

@Component({
  selector: 'app-autocomplete',
  templateUrl: './autocomplete.page.html',
  styleUrls: ['./autocomplete.page.scss'],
})
export class AutocompletePage implements OnInit {

  public title;
  public placeholder;

  public searchField;
  public obs;
  public obj;
  public results;
  public template;

  @Input() public doResearch: boolean;
  @Input() public researchField: string;
  @Input() public researchUntil;

  public loading = false;

  public initialObj;
  public errorMessage;
  public searchInput;

  constructor(
    public modalCtrl: ModalController,
    public navParams: NavParams
  ) {

  }

  ngOnInit() {

    this.searchField = this.navParams.get('searchField');
    this.obs = this.navParams.get('observable');
    this.template = this.navParams.get('template');

    this.initialObj = { ...this.obj };

  }

  public dismiss(result?) {
    this.modalCtrl.dismiss({ result: result });
  }

  public research(result) {

    const input = result[this.researchField];
    if (this.obj.address_context) {
      this.obj.address_context = result['context'];
    }

    this.searchInput = input;

  }

  public researchDone(result) {

    if (result[this.researchUntil.key] === this.researchUntil.value) {
      return true;
    }

    return false;

  }

  public search(event: any) {

    const input = event.detail.value;
    if (!input || input.length === 0) {
      this.obj.address_context = this.initialObj.address_context;
    }
    this.obj[this.searchField] = input;
    this.loading = true;

    this.obs(this.obj).subscribe(r => {
      this.loading = false;
      this.results = r;

      if (this.results && this.results.length === 0) {
        this.errorMessage = 'Geen resultaten gevonden';
      } else {
        this.errorMessage = undefined;
      }

    }, errorMessage => {
      console.error(errorMessage);
    });

  }

  public parseResult(result: any, elNum) {
    let str = result[this.template[elNum - 1]];
    return str;
  }

}
