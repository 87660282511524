import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { LoginPage } from '@pages/account/login/login.page';
import { AuthService } from '@roperetail/ngx-ionic-ecommerce';

@Component({
  selector: 'app-store-stock',
  templateUrl: './store-stock.page.html',
  styleUrls: ['./store-stock.page.scss'],
})
export class StoreStockPage implements OnInit {

  @ViewChild('ropeStoreStock') ropeStoreStock;

  public outfitId;

  constructor(
    public modalCtrl: ModalController,
    public authService: AuthService,
    public navParams: NavParams
  ) {}

  ngOnInit(): void {
    this.outfitId = this.navParams.get("outfit_id");
  }

  public dismiss() {
    this.modalCtrl.dismiss();
  }

  public async showLogin(ev) {

    const pv = ev.productVariant;
    const branchId = ev.branchId;

    const loginModal = await this.modalCtrl.create({
      component: LoginPage,
      componentProps: {
        origin: {
          name: "Winkelvoorraad",
          url: "store-stock",
        },
      },
      cssClass: "big-modal",
    });

    loginModal.onDidDismiss().then(() => {
      if (this.authService.isAuthenticated()) {
        this.ropeStoreStock.showCreateReservation(pv, branchId);
      }
    });

    return await loginModal.present();

  }

}
