import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { VgApiService } from '@videogular/ngx-videogular/core';

@Component({
  selector: 'app-video-player',
  templateUrl: './video-player.page.html',
  styleUrls: ['./video-player.page.scss'],
})
export class VideoPlayerPage implements OnInit {

  public video;

  constructor(
    public modalCtrl: ModalController,
    public navParams: NavParams
  ) { }

  ngOnInit() {
    this.video = this.navParams.get('video');
  }

  public dismiss() {
    this.modalCtrl.dismiss();
  }

  public onPlayerReady(vgApi: VgApiService) {
    this.video.api = vgApi;
    this.video.api.volume = 1;
    this.video.api.play();
  }

}
