import { Component, Input, OnInit } from '@angular/core';
import { NavParams, NavController, ModalController } from '@ionic/angular';
import { NavigatorService } from '@services/core/navigator.service';

@Component({
  selector: 'app-cart-payment-status',
  templateUrl: './cart-payment-status.page.html',
  styleUrls: ['./cart-payment-status.page.scss'],
})
export class CartPaymentStatusPage implements OnInit {

  public transactionId;
  public result;

  constructor(
    private navParams: NavParams,
    private navigator: NavigatorService,
    private modalCtrl: ModalController,
  ) {}

  ngOnInit() {
    this.transactionId = this.navParams.get("transaction_id");
    this.result = this.navParams.get("result");
  }

  public navigate(url) {
    this.modalCtrl.dismiss();
    this.navigator.navigate(url);
  }

}
