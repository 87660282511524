<ion-header>

    <ion-toolbar color="tertiary">
        <ion-buttons slot="end">
            <ion-button (click)="dismiss()">
                Sluit
            </ion-button>
        </ion-buttons>
    </ion-toolbar>

</ion-header>

<ion-content fullscreen #content>

  <div class="blocks" *ngIf="product">

    <div class="block" [ngStyle]="{'background-image': 'url('+outfitImage+')'}"
        style="background-size: contain; background-position: center; min-height: 20vh; display: flex; flex-direction: column; justify-content: flex-end;"
        *ngIf="product">
    </div>

    <rope-product-add-to-cart 
      [product]="product"
      [outfit]="outfit"
      type="modal"
      (modalFitAssistant)="openFitAssistant($event)"
      (modalStoreStock)="goToStoreStock($event)"
      (navigateToCart)="navigator.cart()"
      (navigateToWishlist)="navigator.wishlist()"
    ></rope-product-add-to-cart>

  </div>

</ion-content>

<rope-footer [isModal]="true" [content]="content"></rope-footer>