import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';

@Component({
  selector: 'app-subscription-transaction-review',
  templateUrl: './subscription-transaction-review.page.html',
  styleUrls: ['./subscription-transaction-review.page.scss'],
})
export class SubscriptionTransactionReviewPage implements OnInit {

  public transaction;

  constructor(
    private modalCtrl: ModalController,
    private navParams: NavParams,
  ) { }

  ngOnInit() {
    this.transaction = this.navParams.get('transaction');
  }

  public dismiss(statusObj) {
    this.modalCtrl.dismiss(statusObj);
  }

}
