import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-cart-shipment',
  templateUrl: './cart-shipment.page.html',
  styleUrls: ['./cart-shipment.page.scss'],
})
export class CartShipmentPage implements OnInit {

  constructor(
    private modalCtrl: ModalController,
  ) {}

  async ngOnInit() {

  }

  public dismiss() {
    this.modalCtrl.dismiss();
  }

}
