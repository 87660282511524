import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';

@Component({
  selector: 'app-subscription-consent',
  templateUrl: './subscription-consent.page.html',
  styleUrls: ['./subscription-consent.page.scss'],
})
export class SubscriptionConsentPage implements OnInit {

  public subscriptionTypeId;
  public budget;
  public uuid;

  constructor(
    private modalCtrl: ModalController,
    private navParams: NavParams,
  ) { }

  ngOnInit() {
    this.subscriptionTypeId = this.navParams.get('subscription_type_id');
    this.budget = this.navParams.get('budget');
    this.uuid = this.navParams.get('uuid');
  }

  public dismiss(statusObj) {
    this.modalCtrl.dismiss(statusObj);
  }

}
