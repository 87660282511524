import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';

@Component({
  selector: 'app-subscription-employee',
  templateUrl: './subscription-employee.page.html',
  styleUrls: ['./subscription-employee.page.scss'],
})
export class SubscriptionEmployeePage implements OnInit {

  public employeeId;

  constructor(
    private modalCtrl: ModalController,
    private navParams: NavParams
  ) { }

  ngOnInit() {
    this.employeeId = this.navParams.get('employee_id');
  }

  public dismiss(status) {
    this.modalCtrl.dismiss(status);
  }

}
