<ion-header>
    <ion-toolbar color="tertiary">
        <ion-title></ion-title>
        <ion-buttons slot="end">
            <ion-button (click)="dismiss({ status: 0 })" color="secondary">
                Annuleer
            </ion-button>
        </ion-buttons>
    </ion-toolbar>
</ion-header>

<ion-content #content>

    <rope-subscription-consent
        name="Fashion Box"
        [subscriptionTypeId]="subscriptionTypeId"
        [budget]="budget"
        [uuid]="uuid"
        (onDismiss)="dismiss($event)"
    ></rope-subscription-consent>

</ion-content>

<rope-footer [content]="content" [isModal]="true"></rope-footer>