<ion-header>

  <ion-toolbar no-border color="tertiary">
    <ion-buttons slot="start">
      <ion-button (click)="dismiss()" color="secondary">
        <ion-icon slot="icon-only" name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title>{{ title }}</ion-title>
  </ion-toolbar>

  <ion-toolbar color="primary">
    <ion-searchbar debounce="500" [value]="searchInput" (ionChange)="search($event)" [placeholder]="placeholder">
    </ion-searchbar>
  </ion-toolbar>

</ion-header>

<ion-content fullscreen>

  <ion-list *ngIf="results && results.length > 0">
    <ion-item *ngFor="let result of results" text-wrap
      (click)="doResearch && !researchDone(result) ? research(result) : dismiss(result)" [detail]="doResearch">
      <ion-label text-wrap>
        <p>{{ parseResult(result, 1) }}</p>
        <h2>{{ parseResult(result, 2) }}</h2>
        <p *ngIf="template[2] !== undefined">{{ parseResult(result, 3) }}</p>
      </ion-label>
    </ion-item>
  </ion-list>

  <div *ngIf="(!results || results.length === 0) && loading" class="fullscreen-holder">
    <ion-spinner name="crescent"></ion-spinner>
  </div>

  <div *ngIf="(!results || results.length === 0) && !loading" class="fullscreen-holder">
    <div style="font-size: 1.4rem;" *ngIf="!errorMessage">
      <i>Nog geen resultaten</i>
    </div>
    <div style="font-size: 1.4rem; color: indianred;" *ngIf="errorMessage">
      <i>{{ errorMessage }}</i>
    </div>
  </div>

</ion-content>