<ion-header>
  <ion-toolbar color="primary">
    <ion-title></ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="dismiss({ status : 0 })">
        Sluit
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content #content>

  <rope-subscription-note
    *ngIf="transaction"
    [transaction]="transaction"
    (onDismiss)="dismiss($event)"
  ></rope-subscription-note>

</ion-content>

<rope-footer
  [content]="content"
  [isModal]="true"
></rope-footer>
