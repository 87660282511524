<ion-header *ngIf="checkOrigin()">

    <ion-toolbar color="tertiary">
        <ion-title>Mijn account</ion-title>
        <ion-buttons slot="end">
            <ion-button (click)="dismiss()">
                <ion-icon name="close-circle-outline" slot="icon-only"></ion-icon>
            </ion-button>
        </ion-buttons>
    </ion-toolbar>

</ion-header>

<rope-header 
    *ngIf="!checkOrigin()" 
    mode="root" 
    [useSearch]="true" 
    [useWishlist]="true"
    [useShoppingBag]="true" 
    (navigate)="navigator.navigate($event)" 
    (navigateToSearch)="navigator.search()"
    (navigateToWishlist)="navigator.wishlist()" 
    (navigateToCart)="navigator.cart()"
    (navigateToOpenOrderPayment)="navigator.openOrder()"
></rope-header>

<ion-content fullscreen #content>

    <rope-login
        [origin]="origin"
        [topText]="topText"
        [text]="text"
        [imageUrl]="imageUrl"
        (navigate)="navigator.navigate($event)"
        (onRegister)="register()"
        (onDismiss)="dismiss($event)"
        (onMoveOnAsGuest)="moveOnAsGuest()"
    ></rope-login>
    
</ion-content>

<rope-footer 
  [content]="content" 
  [isModal]="checkOrigin()"
></rope-footer>