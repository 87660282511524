<ion-header>

  <ion-toolbar color="tertiary">
    <ion-title>Bezorgmethode</ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="dismiss()">
        <ion-icon slot="icon-only" name="close-circle-outline"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>

</ion-header>

<ion-content #content>

  <rope-cart-shipment
    (onDismiss)="dismiss()"  
  ></rope-cart-shipment>

</ion-content>

<rope-footer
  [content]="content"
  [isModal]="true"
></rope-footer>
    