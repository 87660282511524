<ion-header>
    <ion-toolbar color="tertiary">
        <ion-buttons slot="end">
            <ion-button (click)="dismiss()">
                Sluit
            </ion-button>
        </ion-buttons>
    </ion-toolbar>
</ion-header>

<ion-content fullscreen #content>

    <rope-store-stock
        #ropeStoreStock
        [outfitId]="outfitId"
        (modalLogin)="showLogin($event)"
    ></rope-store-stock>

</ion-content>

<rope-footer [isModal]="true" [content]="content"></rope-footer>