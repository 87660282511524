import { Injectable } from '@angular/core';
import ropeConfig from '@data/rope.config.json';

@Injectable({
  providedIn: 'root'
})
export class SetupService {

  private config;

  constructor() { 
    this.config = ropeConfig;
  }

  public getConfig() {
    return this.config;
  }

}
