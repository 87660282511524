<ion-header>
  <ion-toolbar color="tertiary">
    <ion-buttons slot="start">
      <ion-button (click)="dismiss()" color="dark">
        <ion-icon slot="icon-only" name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title></ion-title>
  </ion-toolbar>
</ion-header>

<ion-content #content>

  <rope-coupon-check
    (onDismiss)="dismiss()"
  ></rope-coupon-check>

</ion-content>

<rope-footer
  [content]="content"
></rope-footer>