<ion-header>
  <ion-toolbar color="primary">
    <ion-title>Video</ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="dismiss()">
        Sluit
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content #content>

  <vg-player (onPlayerReady)="onPlayerReady($event)">
    <video [vgMedia]="$any(media)" #media id="singleVideo" preload="auto" controls>
      <source [src]="video.url" [type]="video.content_type">
    </video>
  </vg-player>

</ion-content>

<rope-footer [content]="content"></rope-footer>