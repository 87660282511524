import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { SubscriptionTransactionReviewPageRoutingModule } from './subscription-transaction-review-routing.module';

import { SubscriptionTransactionReviewPage } from './subscription-transaction-review.page';
import { RopeNgxIonicEcommerceModule } from '@roperetail/ngx-ionic-ecommerce';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    SubscriptionTransactionReviewPageRoutingModule,
    RopeNgxIonicEcommerceModule
  ],
  declarations: [SubscriptionTransactionReviewPage]
})
export class SubscriptionTransactionReviewPageModule {}
