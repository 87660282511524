import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { AutocompletePage } from '@pages/misc/autocomplete/autocomplete.page';
import { CoreService } from '@roperetail/ngx-ionic-ecommerce';
import { NavigatorService } from '@services/core/navigator.service';

@Component({
  selector: 'app-register',
  templateUrl: './register.page.html',
  styleUrls: ['./register.page.scss'],
})
export class RegisterPage implements OnInit {

  @Input() public origin: string;
  @Input() public guestMode;

  public topText = 'Meld je aan en krijg toegang tot de KiBa Family! Dit is de plek waar je bestellingen kunt plaatsen, winkelreserveringen kunt doen of je kunt aanmelden voor een fashion box!'
  public text = 'Log hier in met je e-mailadres en wachtwoord. Heb je al een account in een van onze winkels aan laten maken? Registreer je dan met dat e-mailadres, deze wordt dan automatisch gekoppeld.';
  public imageUrl = 'https://storage.googleapis.com/kiba-fashion-api-bucket/misc/registerheader_image.jpg';

  constructor(
    public modalCtrl: ModalController,
    public coreService: CoreService,
    public navigator: NavigatorService
  ) {

  }

  ngOnInit() {

  }

  public checkOrigin() {
    return this.origin !== undefined;
  }

  public dismiss(params?: any) {
    this.modalCtrl.dismiss(params ? params : undefined);
  }

  public async modalAutocomplete(event) {

    const type = event.type;
    const props = event.componentProps;

    const modal = await this.modalCtrl.create({
      component: AutocompletePage,
      componentProps: props
    });
    await modal.present();

    modal.onDidDismiss().then((d) => {
      // this.userAddressDetailsComponent.parseAutocompleteResponse(type, d);
    });

  }

}
