<ion-header>
  <ion-toolbar color="tertiary">
    <ion-title class="ion-text-center"></ion-title>
  </ion-toolbar>
</ion-header>

<ion-content #content>

  <rope-cart-result-payment
    [transactionId]="transactionId"
    [result]="result"
    (onNavigate)="navigate($event)"
  ></rope-cart-result-payment>

</ion-content>

<rope-footer
  [content]="content">
</rope-footer>