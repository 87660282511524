<rope-initialize 
  *ngIf="storageReady" 
  [mainTitle]="setupService.getConfig().mainTitle"
  [storeUuid]="setupService.getConfig().storeUuid" 
  [storage]="storage"
  [elementRef]="elementRef"
  (ready)="initializeApp()"
></rope-initialize>

<ion-app *ngIf="coreService.viewReady">

  <ng-container *ngIf="!isDesktop">

    <ion-split-pane contentId="main">

      <ion-menu contentId="main" type="overlay" swipeGesture="false">
        <ion-content>
          <rope-menu 
            #menuComponent 
            [topImage]="'/assets/images/menu-bg.jpg'" 
            [author]="author" 
            [version]="version"
            [closeChildrenOnNotUrl]="'/shop/basics'" 
            (onLogOut)="navigator.checkLogOut()"
          ></rope-menu>
        </ion-content>
      </ion-menu>

      <ion-router-outlet id="main"></ion-router-outlet>

    </ion-split-pane>

  </ng-container>

  <ng-container *ngIf="isDesktop">
    <ion-router-outlet></ion-router-outlet>
  </ng-container>

</ion-app>
