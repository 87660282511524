import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-discount-check',
  templateUrl: './discount-check.page.html',
  styleUrls: ['./discount-check.page.scss'],
})
export class DiscountCheckPage implements OnInit {

  constructor(
    public modalCtrl: ModalController
  ) {}

  ngOnInit() {}

  public dismiss() {
    this.modalCtrl.dismiss();
  }

}
