import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { AutocompletePage } from '@pages/misc/autocomplete/autocomplete.page';
import { UserAddressDetailsComponent } from '@roperetail/ngx-ionic-ecommerce';

@Component({
  selector: 'app-address-detail',
  templateUrl: './address-detail.page.html',
  styleUrls: ['./address-detail.page.scss'],
})
export class AddressDetailPage implements OnInit {

  @ViewChild(UserAddressDetailsComponent, { static: false }) userAddressDetailsComponent: UserAddressDetailsComponent; 

  constructor(
    public modalCtrl: ModalController,
  ) { }

  ngOnInit() {

  }

  public dismiss(statusObj) {
    this.modalCtrl.dismiss(statusObj);
  }

  public async modalAutocomplete(event) {

    const type = event.type;
    const props = event.componentProps;

    const modal = await this.modalCtrl.create({
      component: AutocompletePage,
      componentProps: props
    });
    await modal.present();

    modal.onDidDismiss().then((d) => {
      this.userAddressDetailsComponent.parseAutocompleteResponse(type, d);
    });

  }

}
