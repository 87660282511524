import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { CartPaymentStatusPageRoutingModule } from './cart-payment-status-routing.module';

import { CartPaymentStatusPage } from './cart-payment-status.page';
import { RopeNgxIonicEcommerceModule } from '@roperetail/ngx-ionic-ecommerce';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    CartPaymentStatusPageRoutingModule,
    RopeNgxIonicEcommerceModule
  ],
  declarations: [CartPaymentStatusPage]
})
export class CartPaymentStatusPageModule {}
