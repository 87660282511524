import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-webshop-filters',
  templateUrl: './webshop-filters.page.html',
  styleUrls: ['./webshop-filters.page.scss'],
})
export class WebshopFiltersPage implements OnInit {

  constructor(
    public modalCtrl: ModalController
  ) { }

  ngOnInit(): void {

  }

  public dismiss(filterIds?) {
    this.modalCtrl.dismiss({
      filterIds: filterIds
    });
  }

}
