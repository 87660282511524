import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { AuthService, OutfitService, CoreService } from '@roperetail/ngx-ionic-ecommerce';

@Component({
  selector: 'app-fit-assistant',
  templateUrl: './fit-assistant.page.html',
  styleUrls: ['./fit-assistant.page.scss'],
})
export class FitAssistantPage implements OnInit {

  public currentUser;
  public product;

  public currentSize;
  public sizeLabel: string;

  public currentFit;
  public fitLabel: string;

  public currentProductFit;
  public currentProductFitText;
  public productFitLabel: string;

  public sizes = [
    {
      name: 'tops',
      sizes: ['XS', 'S', 'M', 'L', 'XL', 'XXL']
    },
    {
      name: 'jeans',
      sizes: ['34', '36', '38', '40', '42', '44']
    }
  ];
  public currentSizeType;

  public bestSize;
  public secondBestSize;

  public showRanges = false;
  public sizesPresent = false;

  constructor(
    public modalCtrl: ModalController,
    public navParams: NavParams,
    public authService: AuthService,
    public outfitService: OutfitService,
    public coreService: CoreService
  ) { }

  async ngOnInit() {

    this.product = this.navParams.get('product');

    if (this.product.name.toLowerCase().includes('jeans') || this.product.name.toLowerCase().includes('pantalon') || this.product.name.toLowerCase().includes('hoge broek')) {
      this.currentSizeType = 'jeans';
    } else {
      this.currentSizeType = 'tops';
    }

    if (this.authService.isAuthenticated()) {

      this.currentUser = this.authService.getCurrentUser();
      if (this.currentUser[this.currentSizeType+'_size_avg']) {
        this.sizesPresent = true;
        this.currentSize = Number((Math.round(this.currentUser[this.currentSizeType + '_size_avg'] * 4) / 4).toFixed(2)) * 100;
        this.changeSizeLabel();
      }

    }

    const fullProduct = await this.outfitService.getProductWithOptions(this.product.id).toPromise();
    const fitOption = fullProduct['options'].find(o => o.property.name === 'Pasvorm');
    const vals = [
      {
        identifier: 'Hele maat kleiner',
        name: 'erg klein',
        value: -100
      },
      {
        identifier: 'Halve maat kleiner',
        name: 'klein',
        value: -50
      },
      {
        identifier: 'Normaal',
        name: 'normaal',
        value: 0
      },
      {
        identifier: 'Halve maat groter',
        name: 'groot',
        value: 50
      },
      {
        identifier: 'Hele maat groter',
        name: 'erg groot',
        value: 100
      }
    ];

    if (fitOption) {
      const val = vals.find(v => v.identifier === fitOption.name);
      this.currentProductFit = val.value;
      this.currentProductFitText = val.name;
    } else {
      const val = vals.find(v => v.value === 0);
      this.currentProductFit = val.value;
      this.currentProductFitText = val.name;
    }

    this.changeProductFitLabel();

    this.currentFit = 0;
    this.changeFitLabel();

    this.calculateSizeSuggestion();

  }

  public dismiss() {
    this.modalCtrl.dismiss();
  }

  public goToAdvice() {
    this.sizesPresent = true;
    this.calculateSizeSuggestion();
  }

  public changeSize(event: any) {
    this.currentSize = event.detail.value;
    this.changeSizeLabel();
  }

  public changeSizeLabel() {

    const normalizedSize = this.currentSize / 100;
    const sizeToUse = Number((Math.round(normalizedSize * 4) / 4).toFixed(2));
    const nearest = -Math.round(-normalizedSize);

    const connectors = [
      {
        diff: -0.50,
        label: 'Tussen maat $size_1 en maat $size_2 in'
      },
      {
        diff: -0.25,
        label: 'Een smalle'
      },
      {
        diff: 0.00,
        label: 'Precies'
      },
      {
        diff: 0.25,
        label: 'Een ruime'
      },
      {
        diff: 0.50,
        label: 'Tussen maat $size_1 en maat $size_2 in'
      }
    ];

    const sizeList = this.sizes.find(s => s.name === this.currentSizeType).sizes;
    const size = sizeList[nearest-1];
    const connector = connectors.find(c => c.diff === (sizeToUse-nearest));

    this.sizeLabel = connector.diff === 0.50 ? connector.label.replace('$size_1', size).replace('$size_2', sizeList[nearest]) : 
      (connector.diff === -0.50 ? connector.label.replace('$size_1', sizeList[nearest-2]).replace('$size_2', size) : connector.label+' maat '+size);
    this.calculateSizeSuggestion();

  }

  public changeProductFitLabel() {
    this.productFitLabel = 'Dit product valt ' + this.currentProductFitText;
  }

  public changeFit(event: any) {
    this.currentFit = event.detail.value;
    this.changeFitLabel();
  }

  public changeFitLabel() {

    const fitToUse = Math.round(this.currentFit / 100);
    const labels = ['Ik wil dat het aangesloten valt', 'Ik wil dat het normaal valt zoals op de foto', 'Ik wil dat het oversized valt'];
    this.fitLabel = labels[fitToUse+1];
    this.calculateSizeSuggestion();

  }


  public calculateSizeSuggestion() {

    const avg = (this.currentSize + this.currentFit) / 100;
    const std = this.currentUser && this.currentUser[this.currentSizeType + '_size_std'] ? this.currentUser[this.currentSizeType + '_size_std'] : 0.50;

    const sizeList = this.sizes.find(s => s.name === this.currentSizeType).sizes;
    for (let pv of this.product.product_variants) {

      const sizeNum = sizeList.indexOf(pv.size.name) + 1 + (this.currentProductFit / 100);
      const chance = this.normalCdf((sizeNum + 0.5 - avg) / std) - this.normalCdf((sizeNum - 0.5 - avg) / std);
      pv.fitChance = chance;

    }

    const bestSizes = this.product.product_variants.sort((a, b) => b.fitChance - a.fitChance);
    const bestSize = bestSizes[0];
    const secondBestSize = bestSizes[1];

    if ((Math.round(bestSize.fitChance * 100) / 100) > 0 && (Math.round(secondBestSize.fitChance * 100) / 100) > 0) {
      const sumChance = bestSize.fitChance + secondBestSize.fitChance;
      bestSize.fitChance = bestSize.fitChance / sumChance;
      secondBestSize.fitChance = secondBestSize.fitChance / sumChance;
    }

    this.bestSize = bestSize;
    this.secondBestSize = secondBestSize;

  }

  public normalCdf(X) {
    var T = 1 / (1 + .2316419 * Math.abs(X));
    var D = .3989423 * Math.exp(-X * X / 2);
    var Prob = D * T * (.3193815 + T * (-.3565638 + T * (1.781478 + T * (-1.821256 + T * 1.330274))));
    if (X > 0) {
      Prob = 1 - Prob
    }
    return Prob;
  }

}
