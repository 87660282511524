<ion-content>

  <ion-fab vertical="top" horizontal="end" slot="fixed">
    <ion-fab-button (click)="dismiss()">
      <ion-icon name="close-circle-outline"></ion-icon>
    </ion-fab-button>
  </ion-fab>

  <pinch-zoom style="height: 100vh;">
    <img [src]="src" />
  </pinch-zoom>

</ion-content>
