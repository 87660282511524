import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NavParams, ModalController, ToastController } from '@ionic/angular';
import { FitAssistantPage } from '@pages/misc/fit-assistant/fit-assistant.page';
import { Outfit, Product, CartService, OutfitService, AnalyticsService } from '@roperetail/ngx-ionic-ecommerce';
import { NavigatorService } from '@services/core/navigator.service';
import { StoreStockPage } from '../store-stock/store-stock.page';

@Component({
  selector: 'app-product-add',
  templateUrl: './product-add.page.html',
  styleUrls: ['./product-add.page.scss'],
})
export class ProductAddPage implements OnInit {

  public outfitImage: string;
  public outfit: Outfit;

  public product: Product;
  public currentStock: number;

  public outfitId: number;
  public productPreorder;

  constructor(
    public navParams: NavParams,
    public modalCtrl: ModalController,
    public cartService: CartService,
    public toastCtrl: ToastController,
    public outfitService: OutfitService,
    public route: ActivatedRoute,
    public analyticsService: AnalyticsService,
    public navigator: NavigatorService
  ) {

  }

  ngOnInit() {

    this.outfitImage = this.navParams.get('outfitImage');
    this.outfitId = this.navParams.get('outfitId');
    this.outfit = this.navParams.get('outfit');
    this.productPreorder = this.navParams.get('productPreorder');

    this.outfitService.getProduct(this.navParams.get('productId'), this.outfitId).subscribe(products => {
      this.product = products[0];
    }, error => {
      console.error(error);
    });

  }

  public async openFitAssistant(props) {

    const modal = await this.modalCtrl.create({
      component: FitAssistantPage,
      componentProps: props,
      cssClass: 'big-modal'
    });
    return await modal.present();

  }

  public async goToStoreStock(props) {

    const modal = await this.modalCtrl.create({
      component: StoreStockPage,
      componentProps: props,
      cssClass: 'big-modal'
    });

    return await modal.present();

  }

  public dismiss() {
    this.modalCtrl.dismiss();
  }

}
