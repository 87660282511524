<ion-header>
  <ion-toolbar color="tertiary">
    <ion-buttons slot="start">
      <ion-button (click)="dismiss({ status: 0 })">
        <ion-icon name="close" slot="icon-only"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title></ion-title>
  </ion-toolbar>
</ion-header>

<ion-content #content>

  <rope-subscription-employee 
    [employeeId]="employeeId" 
    (onDismiss)="dismiss($event)"
  ></rope-subscription-employee>

</ion-content>

<rope-footer [content]="content"></rope-footer>