//Core files
import { environment } from '../environments/environment';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

//Angular modules
import { LOCALE_ID, NgModule } from '@angular/core';
import { RouteReuseStrategy } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { registerLocaleData, DatePipe, CurrencyPipe, DecimalPipe } from '@angular/common';
import localeNl from '@angular/common/locales/nl';
registerLocaleData(localeNl); 

//Angular Fire
import { getApp, initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { getFirestore, provideFirestore } from '@angular/fire/firestore/lite';
import { getPerformance, providePerformance } from '@angular/fire/performance';
import { getAnalytics, provideAnalytics } from '@angular/fire/analytics';
import { getRemoteConfig, provideRemoteConfig } from '@angular/fire/remote-config';

//Services
import { NavigatorService } from './services/core/navigator.service';
import { AuthGuardService } from './services/guards/auth-guard.service';
import { SetupService } from '@services/core/setup.service';

//Ionic & Capacitor modules
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { IonicStorageModule } from '@ionic/storage-angular';

//Third party modules
import { Angulartics2Module } from 'angulartics2';
import { NgCalendarModule  } from 'ionic2-calendar';
import { InViewportModule } from 'ng-in-viewport';
import { SwiperModule } from 'swiper/angular';
import { MetafrenzyModule } from 'ngx-metafrenzy';
import { VgBufferingModule } from '@videogular/ngx-videogular/buffering';
import { VgControlsModule } from '@videogular/ngx-videogular/controls';
import { VgCoreModule } from '@videogular/ngx-videogular/core';
import { VgOverlayPlayModule } from '@videogular/ngx-videogular/overlay-play';
import { NgxStarsModule } from 'ngx-stars';
import { PinchZoomModule } from 'ngx-pinch-zoom';

//Ionic Native
import { GoogleAnalytics } from '@ionic-native/google-analytics/ngx';
import { SafariViewController } from '@ionic-native/safari-view-controller/ngx';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { Facebook } from '@ionic-native/facebook/ngx';
import { CallNumber } from '@ionic-native/call-number/ngx';
import { EmailComposer } from '@ionic-native/email-composer/ngx';
import { LaunchNavigator } from '@ionic-native/launch-navigator/ngx';

// Rope NgxIonicEcommerce
import { RopeNgxIonicEcommerceModule } from '@roperetail/ngx-ionic-ecommerce';

//Modals
import { ImagesPageModule } from '@pages/misc/images/images.module';
import { VideoPlayerPageModule } from '@pages/misc/video-player/video-player.module';
import { ProductAddPageModule } from '@pages/shop/product-add/product-add.module';
import { WebshopFiltersPageModule } from '@pages/shop/webshop-filters/webshop-filters.module';
import { StoreStockPageModule } from '@pages/shop/store-stock/store-stock.module';
import { CartShipmentPageModule } from '@pages/core/cart/cart-shipment/cart-shipment.module';
import { DiscountCheckPageModule } from '@pages/core/cart/discount-check/discount-check.module';
import { SubscriptionConsentPageModule } from '@pages/shop/subscription/subscription-consent/subscription-consent.module';
import { SubscriptionTransactionNotePageModule } from '@pages/shop/subscription/subscription-transaction-note/subscription-transaction-note.module';
import { SubscriptionEmployeePageModule } from '@pages/shop/subscription/subscription-employee/subscription-employee.module';
import { SubscriptionTransactionReviewPageModule } from '@pages/shop/subscription/subscription-transaction-review/subscription-transaction-review.module';
import { LoginPageModule } from '@pages/account/login/login.module';
import { RegisterPageModule } from '@pages/account/register/register.module';
import { CartPaymentStatusPageModule } from '@pages/core/cart/cart-payment-status/cart-payment-status.module';
import { AutocompletePageModule } from '@pages/misc/autocomplete/autocomplete.module';
import { FitAssistantPageModule } from '@pages/misc/fit-assistant/fit-assistant.module';
import { AddressDetailPageModule } from '@pages/account/address-detail/address-detail.module';
import { ImageViewerPageModule } from '@pages/misc/image-viewer/image-viewer.module';

@NgModule({
  declarations: [
    AppComponent
  ],
  entryComponents: [],
  imports: [
    BrowserModule, 
    AppRoutingModule,
    HttpClientModule,
    RopeNgxIonicEcommerceModule.forRoot(),
    IonicModule.forRoot({
      mode: 'md'
    }),
    IonicStorageModule.forRoot({
      name: '_rope',
    }),
    BrowserAnimationsModule,
    ReactiveFormsModule,
    Angulartics2Module.forRoot(),
    RopeNgxIonicEcommerceModule.forRoot(),
    NgCalendarModule,
    InViewportModule,
    MetafrenzyModule.forRoot(),
    VgCoreModule,
    VgControlsModule,
    VgOverlayPlayModule,
    VgBufferingModule,
    InViewportModule,
    NgCalendarModule,
    NgxStarsModule,
    SwiperModule,
    PinchZoomModule,
    
    // Angular Fire for website
    provideFirebaseApp(() => initializeApp(environment.firebaseForWebsite)),
    provideFirestore(() => getFirestore()),
    providePerformance(() => getPerformance()),
    provideAnalytics(() => getAnalytics()),
    provideRemoteConfig(() => getRemoteConfig()),
    
    //Angular Fire for Rope Retail Chat
    provideFirebaseApp(() => initializeApp(environment.firebaseForChat, 'rope-retail-chat')),
    provideFirestore(() => getFirestore(getApp('rope-retail-chat'))),

    //Modals
    // MaintenancePageModule,
    RegisterPageModule,
    AutocompletePageModule,
    ProductAddPageModule,
    LoginPageModule,
    WebshopFiltersPageModule,
    StoreStockPageModule,
    CartShipmentPageModule,
    DiscountCheckPageModule,
    SubscriptionConsentPageModule,
    SubscriptionTransactionNotePageModule,
    SubscriptionEmployeePageModule,
    SubscriptionTransactionReviewPageModule,
    VideoPlayerPageModule,
    ImagesPageModule,
    FitAssistantPageModule,
    AddressDetailPageModule,
    CartPaymentStatusPageModule,
    ImageViewerPageModule
  ],
  providers: [
    { 
      provide: RouteReuseStrategy, 
      useClass: IonicRouteStrategy 
    },
    { 
      provide: LOCALE_ID, 
      useValue: 'nl-NL' 
    },
    { 
      provide: Window, 
      useValue: window 
    },
    GoogleAnalytics,
    SafariViewController,
    InAppBrowser,
    Facebook,
    AuthGuardService,
    DatePipe,
    DecimalPipe,
    CurrencyPipe,
    SetupService,
    NavigatorService,
    CallNumber,
    EmailComposer,
    LaunchNavigator
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
